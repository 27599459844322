import imageOption from '../option/image';
import require_image from "$utils/images/require_image";
export default [
    {
        icon: require_image('images/contact/1.png', imageOption),
        label: '位置',
        content: '山东省临沂市郯城县郯城大剧院东侧',
        location: 'http://api.map.baidu.com/geocoder?location=34.621926,118.395165&coord_type=gcj02&output=html&src=webapp.baidu.openAPIdemo',
        target: 'view_window'
    },
    {
        icon: require_image('images/contact/2.png', imageOption),
        label: '电话',
        content: '15552659019',
        location: 'tel:15552659019'
    },
    {
        icon: require_image('images/contact/3.png', imageOption),
        label: '邮箱',
        content: 'yancong@yunkust.com'
    }
];
