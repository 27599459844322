export default function () {
    return [
        {
            label: '*类型',
            data: [
                {
                    label: 'APP',
                    value: 1
                },
                {
                    label: '网站',
                    value: 2
                },
                {
                    label: '商城',
                    value: 3
                },
                {
                    label: '小程序',
                    value: 4
                },
                {
                    label: '其他',
                    value: 5
                }
            ],
            component: 'select',
            key: 'type',
        },
        {
            label: '*预算',
            data: [
                {
                    label: '1-2万',
                    value: '1-2w'
                },
                {
                    label: '3-5万',
                    value: '3-5w'
                },
                {
                    label: '5-8万',
                    value: '5-8w'
                },
                {
                    label: '8-10万',
                    value: '8-10w'
                },
                {
                    label: '10万以上',
                    value: '10w+'
                }
            ],
            component: 'select',
            key: 'budget'
        },
        {
            placeholder: '*称呼姓名',
            key: 'name'
        },
        {
            placeholder: '*联系电话',
            key: 'mobile'
        },
        {
            placeholder: '请输入留言内容',
            component: 'textarea',
            key: 'message'
        }
    ];
}
