var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from "vue-property-decorator";
import { Button } from 'element-ui';
import { vRow, vColumn, vBackground } from "$components/index";
import vFooter from '$views/footer';
import inputData from '../data/input';
import imageOption from '../option/image';
import contactData from '../data/contact';
import VImage from "$components/v-image/lib/main.vue";
import ScrollView from "$components/scroll-view/src/main.vue";
import { vAnimate, vAnimateItem, vAnimateTiming } from '$components/index';
import require_image from "$utils/images/require_image";
let default_1 = class extends Vue {
    constructor() {
        super(...arguments);
        this.imageOption = imageOption;
        // 技术提供
        this.provisionBackground = require_image('images/background.png', imageOption);
        // 输入内容
        this.inputData = inputData();
        // 联系我们
        this.contactData = contactData;
    }
    // 更新函数
    updateInput(item, value) {
        if (this.inputState[item.key] === value)
            return;
        return this.$store.commit('updatedInput', { key: item.key, value });
    }
    submit() {
        return this.$store.dispatch('submit', {
            type: 2
        }).then(() => {
            this.$refs.input && this.$refs.input.map((item) => {
                item.value = '';
            });
        });
    }
    scroll(scroll) {
        return this.$refs.animate && this.$refs.animate.animate(scroll);
    }
};
default_1 = __decorate([
    Component({
        components: {
            ScrollView,
            VImage,
            [Button.name]: Button,
            vBackground,
            vFooter,
            vRow,
            vColumn,
            vAnimate,
            vAnimateItem,
            vAnimateTiming
        },
        computed: {
            svgSize() {
                return this.$store.state.config.mobile ? 320 : 270;
            },
            inputState() {
                return this.$store.state.input;
            }
        }
    })
], default_1);
export default default_1;
